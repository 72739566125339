import React, { useContext, useEffect } from 'react';
import { ProductContext } from '../context/ProductContext.js';
import { useLocation } from 'react-router-dom'; 
import "../assest/SearchResults.css";
import { FavoriteContext } from '../context/FavoriteContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart } from '@fortawesome/free-regular-svg-icons';
import {useImage} from "../context/ImageContext.js";

const SearchResults = () => {
  const { getImageUrl } = useImage();
    const location = useLocation();
  const query = new URLSearchParams(location.search).get('query'); 
  const { state, searchProducts } = useContext(ProductContext);
  const { favorites, addToFavorite, removeFavorite} = useContext(FavoriteContext);
 
 
  useEffect(() => {
    if (query) {
      searchProducts(query); 
    }
  }, [query, searchProducts]);

 
const isFavorite = (product) => {
  return favorites.some(favProduct => favProduct.id === product.id);
};

const handleFavoriteToggle = (event, product) => {
  event.stopPropagation(); 
  event.preventDefault();
  if (isFavorite(product)) {
      removeFavorite(product);
  } else {
      addToFavorite(product);
  }
};
fbq('track', 'Search');
  return (
    <div className='search'>
      
      <h2 className='search-results-title'>"{query}" için arama sonuçları</h2>
      <div className="search-result">
     
      {state.searchs.length === 0 ? (
          <p>Sonuç bulunamadı</p>
      ) : (
         <div className="search-result-product">
          
            <div className="search-result-grid">
            
            {state.searchs.map((product, index) => (
                 <a
                 key={`${product.id}-${index}`}
                 href={`/urun/${product.sef}`}
                 target="_blank"
                 rel="noopener noreferrer"
                 className="search-result-card"
                 style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }} 
               >
                    <div className="search-result-img-container">
                
                    <img
                src={getImageUrl(product.image.i1)}
                alt={product.name} onError={(e) => {
                  e.target.onerror = null;  }}
              />
                <div 
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleFavoriteToggle(e, product)} }
                                        className="favorite-iconn" 
                                    >
                                        <FontAwesomeIcon
                                icon={isFavorite(product) ? SolidHeart : RegularHeart}
                                style={{ color: isFavorite(product) ? 'red' : 'gray', fontSize: '22px' }}
                            />
                                    </div>
                       </div>
                    <div className="search-result-responsive-info">
            <h3 className="search-result-responsive-name">
                {product.baslik}
              </h3>
            <div className="rating">
              {'★'.repeat(5)}
            </div>
            <div className="search-result-pricee">
        {product.fiyat && product.eski_fiyat !== product.fiyat ? (
            <>
                <h2 className="search-result-originall-price" style={{ textDecoration: 'line-through' }}>
                    {product.eski_fiyat.toLocaleString('tr-TR')} TL
                </h2>
                <h2 className="search-result-discountedd-price">
                    {product.fiyat.toLocaleString('tr-TR')} TL
                </h2>
            </>
        ) : (
            <h2 className="search-result-originall-price">
                {product.fiyat.toLocaleString('tr-TR')} TL
            </h2>
        )}
    </div>
            {!product.image.i1 && <p>Ürün fotoğrafı bulunamadı.</p>}
             </div>
                </a>
            ))}
            </div>
            </div>
         
      )}
    </div>
    </div>
    
  );
};

export default SearchResults;