import { Link, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import React  from "react";
import { PaymentContext } from "../../context/PaymentContext";
import { UserProvider } from "../../context/UserContext";


const PaymentResult=()=>{
    const { paymentResult}= useContext(PaymentContext);
    const  {status}=useParams();
    const {allParams}=useParams();

    useEffect(() => {
      paymentResult(allParams);
    }, [allParams]);
    fbq('track', 'Purchase', {value: 0.00, currency: 'USD'});
return(

    <div style={{ textAlign: 'center', marginTop: '50px' }}>
    {status === 'success' && (
      <div style={{ color: 'green' }}>
        <h1>Ödeme Başarılı</h1>
        <p>Ödemeniz başarıyla tamamlandı. Teşekkür ederiz</p>
        <a href="/">Ana Sayfaya Dön</a>
      </div>
    )}

    {status === 'failure' && (
      <div style={{ color: 'red' }}>
        <h1>Ödeme Başarısız</h1>
        <p>Ödeme işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.</p>
        <a href="/">Ana Sayfaya Dön</a>
      </div>
    )}
  </div>

);

}
export default PaymentResult;