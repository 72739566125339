import React, { useContext, useState } from 'react';
import { ProductContext } from '../context/ProductContext.js';
import { useNavigate } from 'react-router-dom';

import "../assest/SearchBar.css";
import { IconButton, InputBase} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


const Searchbar = () => {
  const { searchProducts, state } = useContext(ProductContext);
    const [query, setQuery] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const navigate = useNavigate();

  const handleSearch =(e) => {
    e.preventDefault();
    if (query.trim() !== '') {
      navigate(`/search?query=${query}`, { replace: true }); 
      setIsDropdownVisible(false); 
    } 
  };
  

  const handleInputChanges =  (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.trim() !== '') {
      searchProducts(value); 
      setIsDropdownVisible(true); 
    } else {
      setIsDropdownVisible(false); 
    }
  };
  fbq('track', 'Search');
  return (
    <div className="searchbar">
    <form onSubmit={handleSearch}>
        <InputBase
          placeholder="Binlerce ürün arasından ürün arayabilirsiniz..."
          inputProps={{ 'aria-label': 'search' }}
          value={query}
          onChange={handleInputChanges}
          style={{
            width: '100%',}}
        />
        <IconButton type="submit" aria-label="search">
          <SearchIcon />
        </IconButton>
      </form>
    {isDropdownVisible && (
        <div className="search-results">
          {state.searchs.length > 0 ? (
            state.searchs.map((product) => (
              <div key={product.id} className="search-results-product">
                <a href={`/urun/${product.sef}`}>
                <p>{product.baslik}</p></a>
              </div>
            ))
          ) : (
            <p>Sonuç bulunamadı.</p>
          )}
        </div>
      )}
  </div>
  );
};

export default Searchbar;