import React, { createContext, useReducer, useEffect, useState, useContext } from 'react';

const initialState = {
    card: JSON.parse(localStorage.getItem('card')) || [],
    total: 0,
    cart:[],
    shippingTotal: 0,
    grandTotal:0, 
  };
  
 
  const cardReducer = (state, action) => {
    let updatedCard;
    switch (action.type) {
      case 'REMOVE_FROM_CARD':
      return {
          ...state,
          cart: state.cart.filter(cart=> cart.cart_id !== action.payload), 
        };
      case 'INCREASE_QUANTITY':
        updatedCard = state.cart.map(item =>
          item.id === action.payload.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
        localStorage.setItem('card', JSON.stringify(updatedCard));
        return { ...state, cart: updatedCard };
      case 'DECREASE_QUANTITY':
        updatedCard = state.cart.map(item =>
          item.id === action.payload.id && item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
        localStorage.setItem('card', JSON.stringify(updatedCard));
        return { ...state, cart: updatedCard };
        case 'CALCULATE_TOTAL':
          const totalAmount = state.cart.reduce((acc, product) => {
            return acc + (product.fiyat * (product.quantity || 1));
          }, 0);
          const shippingTotal = state.cart.reduce((acc, product) => {
            return acc + (product.kargo_fiyati || 0);
          }, 0);
          
          return { 
            ...state,  
            total: totalAmount, 
            shippingTotal: shippingTotal.toFixed(2)
          };
        
              case 'FETCH_CARD_SUCCESS':
                return { ...state, cart:  [...state.cart, ...action.payload]  };
          
              case 'FETCH_CARD_ERROR':
                console.error('Error fetching card:', action.payload);
                return { ...state, error: action.payload };
          
      default:
        return state;
    }
  };
  
export const CardContext = createContext();

export const CardProvider=({children})=>{
      const [state, dispatch] = useReducer(cardReducer, initialState);

      
    

  const addToCard = async (product, size) => {
    try{
    const token = localStorage.getItem('token');
    const tkn= localStorage.getItem('tkn');
    const response = await fetch(`https://api.bikuyum.com/api/cart`,{
      method: 'POST',
      headers: {
        'Authorization': `${token}`,
        'Tkn':`${tkn}`, 
        'Content-Type': 'application/json',
        
      },
      body: JSON.stringify({
        urun_id: product,
      size:size })
    });
    const data = await response.json();

    
    if (data.success) {
      fbq('track', 'AddToCart');
      dispatch({ type: 'FETCH_CARD_SUCCESS', payload: product }); 
    }
  } catch (error) {
    console.error("Error adding product to cart:", error);
  }
  
   
};

const getCard = async ()=>{
  try{
  const token = localStorage.getItem('token');
    const tkn= localStorage.getItem('tkn');
    const response = await fetch(`https://api.bikuyum.com/api/cart`,{
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
        'Tkn':`${tkn}`, 
        'Content-Type': 'application/json',
        
      },
    
    });
    const data = await response.json();
    const correctedData = data.map(cart => {
      if (typeof cart.image === 'string') {
        try {
          const imageUrls = JSON.parse(cart.image.replace(/\\/g, ''));
          return { ...cart, image: imageUrls };
        } catch (error) {
          console.error('Image JSON parse edilemedi:', error);
          return cart;
        }
      }
      return cart;
    });
        dispatch({ type: 'FETCH_CARD_SUCCESS', payload: correctedData });
             
          } catch (error) {
         dispatch({ type: 'FETCH_CARD_ERROR', payload: error.message });
          }
};

useEffect(() => {
  getCard();
}, []);

const deleteCard = async (product)=>{
  try{
  const token = localStorage.getItem('token');
    const tkn= localStorage.getItem('tkn');
    const response = await fetch(`https://api.bikuyum.com/api/cart?id=${product}`,{
      method: 'DELETE',
      headers: {
        'Authorization': `${token}`,
        'Tkn':`${tkn}`, 
        'Content-Type': 'application/json',
        
      },
     
    });
    const data = await response.json();
   
    
    if (data.message) {
      dispatch({ type: 'REMOVE_FROM_CARD', payload: product }); 
      
    }
  } catch (error) {
    console.error("Error adding product to cart:", error);
  }
};
useEffect(() => {
  localStorage.setItem('card', JSON.stringify(state.cart));
}, [state.cart]);
    
      const increaseQuantity = (products) => {
        dispatch({ type: 'INCREASE_QUANTITY', payload: products });
      };
    
      const decreaseQuantity = (products) => {
        dispatch({ type: 'DECREASE_QUANTITY', payload: products });
      };
      const calculateTotal = () => {
        dispatch({ type: 'CALCULATE_TOTAL' });
    };
    useEffect(() => {
      calculateTotal(); 
    }, [state.cart]);

    return (
        <CardContext.Provider value={{ deleteCard,   shippingTotal: state.shippingTotal, cart:state.cart,  total: state.total, addToCard, calculateTotal, increaseQuantity, decreaseQuantity, dispatch }}>
            {children}
        </CardContext.Provider>
    )
   
};

