import React, { useState, useEffect, useContext} from 'react';
import "./Card.css";
import { useNavigate } from 'react-router-dom';
import cardd from "./card.png";
import { CardContext } from "../../context/CardContext.js";
import Iller from "../../il-ilce.json";
import { UserContext } from '../../context/UserContext.js';
import Modal from 'react-modal';
import {useImage} from "../../context/ImageContext.js";
import Loader from '../../components/Loader.js';

Modal.setAppElement('#root'); 

const Card = () => {
  const { getImageUrl } = useImage();
  const navigate = useNavigate();
  const { formData: userData } = useContext(UserContext);
  const {state, shippingTotal, cart, total, loading, deleteCard, decreaseQuantity, increaseQuantity } = useContext(CardContext);
  
  const [formData, setFormData] = useState({
    ad: userData?.ad || '',
    soyad: userData?.soyad || '',
    email: userData?.email || '',
    telefon: userData?.telefon || '05',
    tckimlik: userData?.tckimlik || '',
    il: '',
    ilce: '',
    adres: userData?.adres || '',
    siparis_notu: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [iller, setIller] = useState([]);
  const [ilceler, setIlceler] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [salesTermsAccepted, setSalesTermsAccepted] = useState(false);
  const [deliveryTermsAccepted, setDeliveryTermsAccepted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTerm, setCurrentTerm] = useState('');

  useEffect(() => {
    setIller(Iller.iller);
  }, []);



	const getTermsContent = () => {
    switch (currentTerm) {
      case 'kullanım-kosullari':
        return (
          <div>
            <h2>Kullanım Koşulları</h2>
            <p>4. ÜYELİK ŞARTLARI VE YÜKÜMLÜLÜKLERİ

            4.1. Üye olmak isteyen 18 yaşını doldurmuş  kullanıcının, Websitesi'nde bulunan işbu Üyelik Sözleşmesi'ni sunulan formu eksiksiz ve güncel olarak doldurmak  ve belirtilen şekilde  onaylayarak Üyelik başvurusunun “sitemiz” tarafından sms ve e posta ile onaylanması gerekmektedir. Üyelik onayı ile üyelik başlamış olur. Üye adayı üyelik onayı öncesi veya üye olduktan sonra dilediği zaman üyelikten ayrılabilir. Üyeler arasında benzerlik veya menfaat çatışması olduğunda  usulüne uygun yapılan ilk üyelik, yasal engel yok ise geçerli olacaktır. “sitemiz” dilediği üyeye öncelik tanıyabileceği gibi üyelerin üyeliğini askıya alabilir veya üyeliğini sona erdirebilir. Aynı şekilde üye/üyeler, üyelikle ilgili veya  ilanlar ve satışlara ilişkin her türlü yasal prosedüre müracaat ettiğinde, “sitemiz”ı davaya dahil veya ihbar edemeyeceklerdir. Üyeler şifre ve bilgilerini üyelik hesabından değiştirebilir.

4.2. 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca yalnızca "aracı hizmet sağlayıcı" ve 5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun uyarınca "yer sağlayıcı" olması sebebiyle, “sitemiz” Web Sitesi’nde yer alan ve kendisi tarafından yayınlanmamış hiçbir görsel, yazılı veya sair içeriğin gerçekliğinden, güvenilirliğinden, doğruluğundan ya da hukuka uygunluğundan sorumlu değildir ve söz konusu içeriklerin doğruluğunu kontrol etme gibi bir yükümlülüğü bulunmamaktadır. sitemiz  söz konusu içerikleri dileği zaman kontrol etme ve gerekli görürse erişime kapatma veya bir kısmını ve tamamını silme hakkına sahiptir. Dava açma hakkına sahiptir. İlan içeriğinden görsellerden veya herhangi bir nedenle Üçüncü kişi taleplerinden  üye sorumludur. Usulsüz ilan ve içerikten “sitemiz” bir zarara uğrar ise üye bu zararı gidermekle yükümlüdür.</p>
          </div>
        );
      case 'mesafeli-satis':
        return (
          <div>
            <h2>Mesafeli Satış Sözleşmesi</h2>
            <p>1.TARAFLAR
İşbu Sözleşme aşağıdaki taraflar arasında aşağıda belirtilen hüküm ve şartlar çerçevesinde imzalanmıştır. 
A.‘ALICI’ ; (sözleşmede bundan sonra "ALICI" olarak anılacaktır)
 
B.‘SATICI’ ; (sözleşmede bundan sonra "SATICI" olarak anılacaktır)
Ünvan: Bikuyum
ADRES: Hilton İş Merkezi ( Eski Hilton Otel ) İsmet Kaptan Mah Gazi Osmanpaşa Blv. Hilton İş Merkezi, Z14 Konak/İzmir
İş bu sözleşmeyi kabul etmekle ALICI, sözleşme konusu siparişi onayladığı takdirde sipariş konusu bedeli ve varsa kargo ücreti, vergi gibi belirtilen ek ücretleri ödeme yükümlülüğü altına gireceğini ve bu konuda bilgilendirildiğini peşinen kabul eder.
2.TANIMLAR
İşbu sözleşmenin uygulanmasında ve yorumlanmasında aşağıda yazılı terimler karşılarındaki yazılı açıklamaları ifade edeceklerdir.
BAKAN: Gümrük ve Ticaret Bakanı’nı,
BAKANLIK: Gümrük ve Ticaret  Bakanlığı’nı,
KANUN: 6502 sayılı Tüketicinin Korunması Hakkında Kanun’u,
YÖNETMELİK: Mesafeli Sözleşmeler Yönetmeliği’ni (RG:27.11.2014/29188)
HİZMET: Bir ücret veya menfaat karşılığında yapılan ya da yapılması taahhüt edilen mal sağlama dışındaki her türlü tüketici işleminin konusunu ,
SATICI: Ticari veya mesleki faaliyetleri kapsamında tüketiciye mal sunan veya mal sunan adına veya hesabına hareket eden şirketi,
ALICI: Bir mal veya hizmeti ticari veya mesleki olmayan amaçlarla edinen, kullanan veya yararlanan gerçek ya da tüzel kişiyi,
SİTE: SATICI’ya ait internet sitesini,
SİPARİŞ VEREN: Bir mal veya hizmeti SATICI’ya ait internet sitesi üzerinden talep eden gerçek ya da tüzel kişiyi,
TARAFLAR: SATICI ve ALICI’yı,
SÖZLEŞME: SATICI ve ALICI arasında akdedilen işbu sözleşmeyi,
MAL: Alışverişe konu olan taşınır eşyayı ve elektronik ortamda kullanılmak üzere hazırlanan yazılım, ses, görüntü ve benzeri gayri maddi malları ifade eder.
3.KONU
İşbu Sözleşme, ALICI’nın, SATICI’ya ait internet sitesi üzerinden elektronik ortamda siparişini verdiği aşağıda nitelikleri ve satış fiyatı belirtilen ürünün satışı ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerini düzenler.
Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
4. SATICI BİLGİLERİ
Ünvanı: Bikuyum
Adres: Hilton İş Merkezi ( Eski Hilton Otel ) İsmet Kaptan Mah Gazi Osmanpaşa Blv. Hilton İş Merkezi, Z14 Konak/İzmir
Telefon: 05442323517

5. ALICI BİLGİLERİ
Teslim edilecek kişi
Teslimat Adresi
Telefon
Faks
Eposta/kullanıcı adı
6. SİPARİŞ VEREN KİŞİ BİLGİLERİ
Ad/Soyad/Unvan
Adres
Telefon
Faks
Eposta/kullanıcı adı
7. SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ
1. Malın /Ürün/Ürünlerin/ Hizmetin temel özelliklerini (türü, miktarı, marka/modeli, rengi, adedi) SATICI’ya ait internet sitesinde yayınlanmaktadır. Satıcı tarafından kampanya düzenlenmiş ise ilgili ürünün temel özelliklerini kampanya süresince inceleyebilirsiniz. Kampanya tarihine kadar geçerlidir.
7.2. Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
7.3. Sözleşme konusu mal ya da hizmetin tüm vergiler dâhil satış fiyatı aşağıda gösterilmiştir.
 
Ürün AçıklamasıAdetBirim FiyatıAra Toplam
(KDV Dahil)
Kargo Tutarı
Toplam :
 
Ödeme Şekli ve Planı
Teslimat Adresi
Teslim Edilecek kişi
Fatura Adresi
Sipariş Tarihi
Teslimat tarihi
Teslim şekli
 
7.4.  Ürün sevkiyat masrafı olan kargo ücreti ALICI tarafından ödenecektir.
8. FATURA BİLGİLERİ
Ad/Soyad/Unvan
Adres
Telefon
Faks
Eposta/kullanıcı adı
Fatura teslim : Fatura sipariş teslimatı sırasında fatura adresine sipariş ile birlikte 
teslim edilecektir.
 
 
9. GENEL HÜKÜMLER
9.1. ALICI, SATICI’ya ait internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik ortamda gerekli teyidi verdiğini kabul, beyan ve taahhüt eder. ALICI’nın; Ön Bilgilendirmeyi elektronik ortamda teyit etmesi, mesafeli satış sözleşmesinin kurulmasından evvel, SATICI tarafından ALICI' ya verilmesi gereken adresi, siparişi verilen ürünlere ait temel özellikleri, ürünlerin vergiler dâhil fiyatını, ödeme ve teslimat bilgilerini de doğru ve eksiksiz olarak edindiğini kabul, beyan ve taahhüt eder.
9.2. Sözleşme konusu her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile ALICI' nın yerleşim yeri uzaklığına bağlı olarak internet sitesindeki ön bilgiler kısmında belirtilen süre zarfında ALICI veya ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürünün ALICI’ya teslim edilememesi durumunda, ALICI’nın sözleşmeyi feshetme hakkı saklıdır. 
9.3. SATICI, Sözleşme konusu ürünü eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri, kullanım kılavuzları işin gereği olan bilgi ve belgeler ile teslim etmeyi, her türlü ayıptan arî olarak yasal mevzuat gereklerine göre sağlam, standartlara uygun bir şekilde işi doğruluk ve dürüstlük esasları dâhilinde ifa etmeyi, hizmet kalitesini koruyup yükseltmeyi, işin ifası sırasında gerekli dikkat ve özeni göstermeyi, ihtiyat ve öngörü ile hareket etmeyi kabul, beyan ve taahhüt eder.
9.4. SATICI, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan ALICI’yı bilgilendirmek ve açıkça onayını almak suretiyle eşit kalite ve fiyatta farklı bir ürün tedarik edebilir.
9.5. SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine getiremezse, bu durumu, öğrendiği tarihten itibaren 3 gün içinde yazılı olarak tüketiciye bildireceğini, 14 günlük süre içinde toplam bedeli ALICI’ya iade edeceğini kabul, beyan ve taahhüt eder. 
9.6. ALICI, Sözleşme konusu ürünün teslimatı için işbu Sözleşme’yi elektronik ortamda teyit edeceğini, herhangi bir nedenle sözleşme konusu ürün bedelinin ödenmemesi ve/veya banka kayıtlarında iptal edilmesi halinde, SATICI’nın sözleşme konusu ürünü teslim yükümlülüğünün sona ereceğini kabul, beyan ve taahhüt eder.
9.7. ALICI, Sözleşme konusu ürünün ALICI veya ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa tesliminden sonra ALICI'ya ait kredi kartının yetkisiz kişilerce haksız kullanılması sonucunda sözleşme konusu ürün bedelinin ilgili banka veya finans kuruluşu tarafından SATICI'ya ödenmemesi halinde, ALICI Sözleşme konusu ürünü 3 gün içerisinde nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya iade edeceğini kabul, beyan ve taahhüt eder.
9.8. SATICI, tarafların iradesi dışında gelişen, önceden öngörülemeyen ve tarafların borçlarını yerine getirmesini engelleyici ve/veya geciktirici hallerin oluşması gibi mücbir sebepler halleri nedeni ile sözleşme konusu ürünü süresi içinde teslim edemez ise, durumu ALICI'ya bildireceğini kabul, beyan ve taahhüt eder. ALICI da siparişin iptal edilmesini, sözleşme konusu ürünün varsa emsali ile değiştirilmesini ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesini SATICI’dan talep etme hakkını haizdir. ALICI tarafından siparişin iptal edilmesi halinde ALICI’nın nakit ile yaptığı ödemelerde, ürün tutarı 14 gün içinde kendisine nakden ve defaten ödenir. ALICI’nın kredi kartı ile yaptığı ödemelerde ise, ürün tutarı, siparişin ALICI tarafından iptal edilmesinden sonra 14 gün içerisinde ilgili bankaya iade edilir. ALICI, SATICI tarafından kredi kartına iade edilen tutarın banka tarafından ALICI hesabına yansıtılmasına ilişkin ortalama sürecin 2 ile 3 haftayı bulabileceğini, bu tutarın bankaya iadesinden sonra ALICI’nın hesaplarına yansıması halinin tamamen banka işlem süreci ile ilgili olduğundan, ALICI, olası gecikmeler için SATICI’yı sorumlu tutamayacağını kabul, beyan ve taahhüt eder.
9.9. SATICININ, ALICI tarafından siteye kayıt formunda belirtilen veya daha sonra kendisi tarafından güncellenen adresi, e-posta adresi, sabit ve mobil telefon hatları ve diğer iletişim bilgileri üzerinden mektup, e-posta, SMS, telefon görüşmesi ve diğer yollarla iletişim, pazarlama, bildirim ve diğer amaçlarla ALICI’ya ulaşma hakkı bulunmaktadır. ALICI, işbu sözleşmeyi kabul etmekle SATICI’nın kendisine yönelik yukarıda belirtilen iletişim faaliyetlerinde bulunabileceğini kabul ve beyan etmektedir.
9.10. ALICI, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. Teslimden sonra mal/hizmetin özenle korunması borcu, ALICI’ya aittir. Cayma hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Fatura iade edilmelidir.
9.11. ALICI ile sipariş esnasında kullanılan kredi kartı hamilinin aynı kişi olmaması veya ürünün ALICI’ya tesliminden evvel, siparişte kullanılan kredi kartına ilişkin güvenlik açığı tespit edilmesi halinde, SATICI, kredi kartı hamiline ilişkin kimlik ve iletişim bilgilerini, siparişte kullanılan kredi kartının bir önceki aya ait ekstresini yahut kart hamilinin bankasından kredi kartının kendisine ait olduğuna ilişkin yazıyı ibraz etmesini ALICI’dan talep edebilir. ALICI’nın talebe konu bilgi/belgeleri temin etmesine kadar geçecek sürede sipariş dondurulacak olup, mezkur taleplerin 24 saat içerisinde karşılanmaması halinde ise SATICI, siparişi iptal etme hakkını haizdir.
9.12. ALICI, SATICI’ya ait internet sitesine üye olurken verdiği kişisel ve diğer sair bilgilerin gerçeğe uygun olduğunu, SATICI’nın bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları, SATICI’nın ilk bildirimi üzerine derhal, nakden ve defaten tazmin edeceğini beyan ve taahhüt eder.
9.13. ALICI, SATICI’ya ait internet sitesini kullanırken yasal mevzuat hükümlerine riayet etmeyi ve bunları ihlal etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler tamamen ve münhasıran ALICI’yı bağlayacaktır.
9.14. ALICI, SATICI’ya ait internet sitesini hiçbir şekilde kamu düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir amaç için, başkalarının maddi ve manevi haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, üye başkalarının hizmetleri kullanmasını önleyici veya zorlaştırıcı faaliyet (spam, virus, truva atı, vb.) işlemlerde bulunamaz.
9.15. SATICI’ya ait internet sitesinin üzerinden, SATICI’nın kendi kontrolünde olmayan ve/veya başkaca üçüncü kişilerin sahip olduğu ve/veya işlettiği başka web sitelerine ve/veya başka içeriklere link verilebilir. Bu linkler ALICI’ya yönlenme kolaylığı sağlamak amacıyla konmuş olup herhangi bir web sitesini veya o siteyi işleten kişiyi desteklememekte ve Link verilen web sitesinin içerdiği bilgilere yönelik herhangi bir garanti niteliği taşımamaktadır.
9.16. İşbu sözleşme içerisinde sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen sorumlu olup, SATICI’yı bu ihlallerin hukuki ve cezai sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk alanına intikal ettirilmesi halinde, SATICI’nın üyeye karşı üyelik sözleşmesine uyulmamasından dolayı tazminat talebinde bulunma hakkı saklıdır.
10. CAYMA HAKKI
10.1. ALICI; mesafeli sözleşmenin mal satışına ilişkin olması durumunda, ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde, SATICI’ya bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir. Hizmet sunumuna ilişkin mesafeli sözleşmelerde ise, bu süre sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz. Cayma hakkının kullanımından kaynaklanan masraflar SATICI’ ya aittir. ALICI, iş bu sözleşmeyi kabul etmekle, cayma hakkı konusunda bilgilendirildiğini peşinen kabul eder.
10.2. Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde SATICI' ya iadeli taahhütlü posta, faks veya eposta ile yazılı bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen "Cayma Hakkı Kullanılamayacak Ürünler" hükümleri çerçevesinde kullanılmamış olması şarttır. Bu hakkın kullanılması halinde, 
a) 3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)
b) İade formu,
c) İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.
d) SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10 günlük süre içerisinde toplam bedeli ve ALICI’yı borç altına sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde malı iade almakla yükümlüdür.
e) ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkânsızlaşırsa ALICI kusuru oranında SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu değildir. 
f) Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen kampanya limit tutarının altına düşülmesi halinde kampanya kapsamında faydalanılan indirim miktarı iptal edilir.
11. CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER
ALICI’nın isteği veya açıkça kişisel ihtiyaçları doğrultusunda hazırlanan ve geri gönderilmeye müsait olmayan, iç giyim alt parçaları, mayo ve bikini altları, makyaj malzemeleri, tek kullanımlık ürünler, çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme ihtimali olan mallar, ALICI’ya teslim edilmesinin ardından ALICI tarafından ambalajı açıldığı takdirde iade edilmesi sağlık ve hijyen açısından uygun olmayan ürünler, teslim edildikten sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan ürünler, Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınlara ilişkin mallar, Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallar, ile ses veya görüntü kayıtlarının, kitap, dijital içerik, yazılım programlarının, veri kaydedebilme ve veri depolama cihazlarının,  bilgisayar sarf malzemelerinin, ambalajının ALICI tarafından açılmış olması halinde iadesi Yönetmelik gereği mümkün değildir. Ayrıca Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin cayma hakkının kullanılması da Yönetmelik gereği mümkün değildir.
Kozmetik ve kişisel bakım ürünleri, iç giyim ürünleri, mayo, bikini, kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD ve kasetler ile kırtasiye sarf malzemeleri (toner, kartuş, şerit vb.) iade edilebilmesi için ambalajlarının açılmamış, denenmemiş, bozulmamış ve kullanılmamış olmaları gerekir. 
 
 
12. TEMERRÜT HALİ VE HUKUKİ SONUÇLARI
ALICI, ödeme işlemlerini  kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini ALICI’dan talep edebilir ve her koşulda ALICI’nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI’nın uğradığı zarar ve ziyanını ödeyeceğini kabul, beyan ve taahhüt eder
13. YETKİLİ MAHKEME
İşbu sözleşmeden doğan uyuşmazlıklarda şikayet ve itirazlar, aşağıdaki kanunda belirtilen parasal sınırlar dâhilinde tüketicinin yerleşim yerinin bulunduğu veya tüketici işleminin yapıldığı yerdeki tüketici sorunları hakem heyetine veya tüketici mahkemesine yapılacaktır. Parasal sınıra ilişkin bilgiler aşağıdadır:
01/01/2017 tarihinden itibaren geçerli olmak üzere, 2017 yılı için tüketici hakem heyetlerine yapılacak başvurularda değeri:
a) 2.400 (iki bin dört yüz) Türk Lirasının altında bulunan uyuşmazlıklarda ilçe tüketici hakem heyetleri,
b) Büyükşehir statüsünde olan illerde 2.400 (iki bin dört yüz) Türk Lirası ile 3.610 (üç bin altı yüz on) Türk Lirası arasındaki uyuşmazlıklarda il tüketici hakem heyetleri,
c) Büyükşehir statüsünde olmayan illerin merkezlerinde 3.610 (üç bin altı yüz on) Türk Lirasının altında bulunan uyuşmazlıklarda il tüketici hakem heyetleri,
ç) Büyükşehir statüsünde olmayan illere bağlı ilçelerde 2.400 (iki bin dört yüz) Türk Lirası ile 3.610 (üç bin altı yüz on) Türk Lirası arasındaki uyuşmazlıklarda il tüketici hakem heyetleri görevli kılınmışlardır.
İşbu Sözleşme ticari amaçlarla yapılmaktadır.
14. YÜRÜRLÜK
ALICI, Site üzerinden verdiği siparişe ait ödemeyi gerçekleştirdiğinde işbu sözleşmenin tüm şartlarını kabul etmiş sayılır. SATICI, siparişin gerçekleşmesi öncesinde işbu sözleşmenin sitede ALICI tarafından okunup kabul edildiğine dair onay alacak şekilde gerekli yazılımsal düzenlemeleri yapmakla yükümlüdür. </p>
          </div>
        );
      case 'teslimat-iade':
        return (
          <div>
            <h2>Teslimat ve İade Sözleşmesi</h2>
            <p>GENEL:
 
 1.Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme formunu ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız.
 2.Alıcılar, satın aldıkları ürünün satış ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer yasalara tabidir. 3.Ürün sevkiyat masrafı olan kargo ücretleri alıcılar tarafından ödenecektir.
 4.Satın alınan her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile alıcının gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürün teslim edilmez ise, Alıcılar sözleşmeyi sona erdirebilir.
 5.Satın alınan ürün, eksiksiz ve siparişte belirtilen niteliklere uygun ve varsa garanti belgesi, kullanım kılavuzu gibi belgelerle teslim edilmek zorundadır.
 6.Satın alınan ürünün satılmasının imkansızlaşması durumunda, satıcı bu durumu öğrendiğinden itibaren 3 gün içinde yazılı olarak alıcıya bu durumu bildirmek zorundadır. 14 gün içinde de toplam bedel Alıcı’ya iade edilmek zorundadır.
  
 SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:
  
 7.Alıcı, satın aldığı ürün bedelini ödemez veya banka kayıtlarında iptal ederse, Satıcının ürünü teslim yükümlülüğü sona erer.
  
 KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:
  
 8.Ürün teslim edildikten sonra, alıcının ödeme yaptığı kredi kartının yetkisiz kişiler tarafından haksız olarak kullanıldığı tespit edilirse ve satılan ürün bedeli ilgili banka veya finans kuruluşu tarafından Satıcı’ya ödenmez ise, Alıcı, sözleşme konusu ürünü 3 gün içerisinde nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya iade etmek zorundadır.
  
 ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE:
  
 9. Satıcının öngöremeyeceği mücbir sebepler oluşursa ve ürün süresinde teslim edilemez ise, durum Alıcı’ya bildirilir. Alıcı, siparişin iptalini, ürünün benzeri ile değiştirilmesini veya engel ortadan kalkana dek teslimatın ertelenmesini talep edebilir. Alıcı siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren 14 gün içinde kendisine nakden bu ücret ödenir. Alıcı, ödemeyi kredi kartı ile yapmış ise ve iptal ederse, bu iptalden itibaren yine 14 gün içinde ürün bedeli bankaya iade edilir, ancak bankanın alıcının hesabına 2-3 hafta içerisinde aktarması olasıdır.
  
 ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ:
  
 10.Alıcı, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. ALICI , Teslimden sonra mal/hizmeti özenle korunmak zorundadır. Cayma hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Ürünle birlikte Fatura da iade edilmelidir.
  
 CAYMA HAKKI:
  
 11.ALICI; satın aldığı ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde, SATICI’ya aşağıdaki iletişim bilgileri üzerinden bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir.
  
 12.SATICININ CAYMA HAKKI BİLDİRİMİ YAPILACAK İLETİŞİM BİLGİLERİ:
 ŞİRKET
 Firma Ünvanı: Bikuyum
 Adres: Hilton İş Merkezi ( Eski Hilton Otel ) İsmet Kaptan Mah Gazi Osmanpaşa Blv. Hilton İş Merkezi, Z14 Konak/İzmir
 Tel: 05442323517
  
 CAYMA HAKKININ SÜRESİ:
 13.Alıcı, satın aldığı eğer bir hizmet ise, bu 2 günlük süre sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz.
 14.Cayma hakkının kullanımından kaynaklanan masraflar SATICI’ ya aittir.
 15.Cayma hakkının kullanılması için 2 (iki) günlük süre içinde SATICI’ ya iadeli taahhütlü posta, faks veya eposta ile yazılı bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen “Cayma Hakkı Kullanılamayacak Ürünler” hükümleri çerçevesinde kullanılmamış olması şarttır.
  
 CAYMA HAKKININ KULLANIMI:
  
 16.3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)
 17.İade formu, İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.
  
 İADE KOŞULLARI:
  
 18.SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 2 günlük süre içerisinde toplam bedeli ve ALICI’yı borç altına sokan belgeleri ALICI’ ya iade etmek ve 2 günlük süre içerisinde malı iade almakla yükümlüdür.
 19.ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkansızlaşırsa ALICI kusuru oranında SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu değildir.
 20.Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen kampanya limit tutarının altına düşülmesi halinde kampanya kapsamında faydalanılan indirim miktarı iptal edilir.
  
 CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER:
  
 21. Fiziki ürünlerin hiçbiri iade olarak kabul edilmeyecektir.
 22. Web site, yazılım ve kodlama gerektiren hizmetlerinin hiçbiri iade olarak kabul edilmeyecektir.
  
 TEMERRÜT HALİ VE HUKUKİ SONUÇLARI
  
 23.ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini ALICI’dan talep edebilir ve her koşulda ALICI’nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI’nın uğradığı zarar ve ziyanını ödeyeceğini kabul eder.
  
 ÖDEME VE TESLİMAT
  
 24.Banka Havalesi veya EFT (Elektronik Fon Transferi) yaparak, sitede bulunan banka hesaplarımızdan (TL) herhangi birine yapabilirsiniz.
 25.Sitemiz üzerinden kredi kartlarınız ile, Her türlü kredi kartınıza online tek ödeme ya da online taksit imkanlarından yararlanabilirsiniz. Online ödemelerinizde siparişiniz sonunda kredi kartınızdan tutar çekim işlemi gerçekleşecektir.</p>
          </div>
        );
      default:
        return null;
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.ad.trim()) errors.ad = 'Ad alanı zorunludur';
    if (!formData.soyad.trim()) errors.soyad = 'Soyad alanı zorunludur';
    if (!formData.email.trim()) errors.email = 'Email alanı zorunludur';
    if (!formData.telefon || formData.telefon.length < 11) errors.telefon = 'Geçerli bir telefon numarası giriniz';
    if (!validateTCKN(formData.tckimlik)) errors.tckimlik = 'Geçersiz TC Kimlik No';
    if (!formData.il) errors.il = 'İl seçimi zorunludur';
    if (!formData.ilce) errors.ilce = 'İlçe seçimi zorunludur';
    if (!formData.adres.trim()) errors.adres = 'Adres alanı zorunludur';
    return errors;
  };

  const validateTCKN = (tckn) => {
    if (tckn.length !== 11 || !/^\d+$/.test(tckn)) return false;
    if (tckn === "11111111111") return true;
    const digits = tckn.split('').map(Number);
    if (digits[0] === 0) return false;

    const oddSum = digits[0] + digits[2] + digits[4] + digits[6] + digits[8];
    const evenSum = digits[1] + digits[3] + digits[5] + digits[7];
    
    const tenthDigit = (oddSum * 7 - evenSum) % 10;
    if (tenthDigit !== digits[9]) return false;

    const eleventhDigit = digits.slice(0, 10).reduce((a, b) => a + b, 0) % 10;
    return eleventhDigit === digits[10];
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'telefon') {
      handlePhoneInput(e);
      return;
    }

    if (name === 'tckimlik') {
      if (value[0] === '0') return;
      if (/^\d*$/.test(value) && value.length <= 11) {
        setFormData(prev => ({ ...prev, [name]: value }));
      }
      return;
    }

    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneInput = (e) => {
    const { value } = e.target;

    if (value.length === 0) {
      setFormData(prev => ({ ...prev, telefon: '05' }));
    } else if (value.length === 1 && value !== "0") {
      setFormData(prev => ({ ...prev, telefon: '05' }));
    } else {
      const sanitizedValue = value.replace(/[^0-9]/g, '');
      if (sanitizedValue.length > 11) {
        setFormData(prev => ({ ...prev, telefon: sanitizedValue.substring(0, 11) }));
      } else {
        setFormData(prev => ({ ...prev, telefon: sanitizedValue }));
      }
    }
  };

  const formatPhoneNumber = (number) => {
    return number.replace(/(\d{4})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
  };

  const handleIlChange = (event) => {
    const ilSecimi = event.target.value;
    setFormData(prev => ({ ...prev, il: ilSecimi, ilce: '' }));
    
    const secilenIl = iller.find((il) => il.il_adi === ilSecimi);
    setIlceler(secilenIl ? secilenIl.ilceler : []);
  };

  const handleIncreaseQuantity = (product) => {
    if (product.quantity < product.stok) {
      increaseQuantity(product);
    } else {
      alert("Bu üründen yeterli stok bulunmamaktadır.");
    }
  };

  const handleSubmit = async (event) => {
  event.preventDefault();
  
  if (!termsAccepted || !salesTermsAccepted || !deliveryTermsAccepted) {
    alert("Lütfen tüm sözleşmeleri kabul edin.");
    return;
  }

  const errors = validateForm();
  if (Object.keys(errors).length > 0) {
    setFormErrors(errors);
    window.scrollTo(0, 0);
    return;
  }

  try {
    navigate("/payment", { 
      state: { 
        total: parseFloat(total) + parseFloat(shippingTotal), 
        customerForm: formData,
        cart: cart

      }
    });
  } catch (error) {
    console.error('Hata:', error);
    alert('İşlem sırasında bir hata oluştu');
  }
};

  if (cart.length === 0) {
    return (
      <div className='card-container'>
        <div className='card'>
          <img src={cardd} alt="card-resim" />
          <p>Sepetinizde ürün bulunmamaktadır.</p>
          <a href="/"><button>Alışverişe Başla</button></a>
        </div>
      </div>
    );
  }
  if (loading) return <Loader />;
  return (
    <div>
      <div className="checkout-page">
        <div className='card-product'>
          <div className='card-title'><span>Sepetim</span></div>
          <div className="product-listt">
            {cart.map((product, index) => (
              <div className='card-item' key={index}>
                <div className='card-product-header'>
                  <span>Satıcı: <a href={`/${product.magaza_sef}/all`}><span>{product.magaza_adi}</span></a></span>
                </div>
                <hr className='hr' />
                <div className='card-product-content'>
                  <div className='card-product-info'>
                    <a href={`/urun/${product.sef}`}>
                      <img src={getImageUrl(product.image.i1)} alt={product.baslik} className='product-imagee' />
                    </a>
                    <a href={`/urun/${product.sef}`}>
                      <p>{product.baslik}</p>
                      {product.size !== 0 && product.size !== 15 && (
                        <span>({product.size} numara 150 TL fiyat farkı eklenmiştir)</span>
                      )}
                    </a>
                  </div>
                  <div className='card-quantity'>
                    <button onClick={() => decreaseQuantity(product)}>-</button>
                    <p>{product.quantity || 1}</p>
                    <button onClick={() => handleIncreaseQuantity(product)}>+</button>
                  </div>
                  <div className='card-price'>
                    <p>{(product.fiyat !== undefined ? product.fiyat : 0).toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}TL</p>
                  </div>
                  <div className='card-remove'>
                    <button className="delete-button" onClick={() => deleteCard(product.cart_id)}>🗑️</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="order-summary">
          <div className='order-summary-first'>
            <div className="summary-item">
              <span>Ara Tutar</span>
              <span>{total.toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</span>
            </div>
            <div className="summary-item">
              <span>Kargo Ücreti</span>
              <span>{shippingTotal}TL</span>
            </div>
            <div className="summary-item-total">
              <span>Toplam Tutar</span>
              <span>{(parseFloat(total) + parseFloat(shippingTotal)).toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</span>
            </div>
          </div>

          <div className='order-summary-second'>
            <form className="checkout-form" onSubmit={handleSubmit}>
              <div className="checkout-form-group">
                <div>
                  <label>Ad*</label>
                  <input
                    type="text"
                    name="ad"
                    value={formData.ad}
                    onChange={handleInputChange}
                    placeholder="Ad*"
                    required
                  />
                  {formErrors.ad && <span className="error">{formErrors.ad}</span>}
                </div>
                <div>
                  <label>Soyad*</label>
                  <input
                    type="text"
                    name="soyad"
                    value={formData.soyad}
                    onChange={handleInputChange}
                    placeholder="Soyad*"
                    required
                  />
                  {formErrors.soyad && <span className="error">{formErrors.soyad}</span>}
                </div>
              </div>

              <div className="checkout-form-group">
                <div>
                  <label>Email*</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email*"
                    required
                  />
                  {formErrors.email && <span className="error">{formErrors.email}</span>}
                </div>
                <div>
                  <label>Telefon*</label>
                  <input
                    type="tel"
                    name="telefon"
                    value={formatPhoneNumber(formData.telefon)}
                    onChange={handleInputChange}
                    placeholder="Telefon*"
                    maxLength="14"
                    required
                  />
                  {formErrors.telefon && <span className="error">{formErrors.telefon}</span>}
                </div>
              </div>

              <div className="checkout-form-group">
                <div className='tckimlik'>
                  <label>Fatura İçin TC Kimlik No*</label>
                  <input
                    type="text"
                    name="tckimlik"
                    value={formData.tckimlik}
                    onChange={handleInputChange}
                    placeholder="Fatura İçin TC Kimlik No"
                    maxLength="11"
                    required
                  />
                  {formErrors.tckimlik && <span className="error">{formErrors.tckimlik}</span>}
                </div>
              </div>

              <div className="checkout-form-group">
                <select
                  name="il"
                  value={formData.il}
                  onChange={handleIlChange}
                  required
                >
                  <option value="">Lütfen Bir İl Seçiniz</option>
                  {iller.map((il, index) => (
                    <option key={index} value={il.il_adi}>
                      {il.il_adi}
                    </option>
                  ))}
                </select>
                {formErrors.il && <span className="error">{formErrors.il}</span>}

                <select
                  name="ilce"
                  value={formData.ilce}
                  onChange={handleInputChange}
                  required
                  disabled={!formData.il}
                >
                  <option value="">Lütfen Bir İlçe Seçiniz</option>
                  {ilceler.map((ilce, index) => (
                    <option key={index} value={ilce.ilce_adi}>
                      {ilce.ilce_adi}
                    </option>
                  ))}
                </select>
                {formErrors.ilce && <span className="error">{formErrors.ilce}</span>}
              </div>

              <div className="checkout-form-group">
                <label>Sipariş Adresiniz*</label>
                <textarea
                  name="adres"
                  value={formData.adres}
                  onChange={handleInputChange}
                  placeholder="Sipariş Adresiniz"
                  required
                />
                {formErrors.adres && <span className="error">{formErrors.adres}</span>}
              </div>

              <div className="checkout-form-group">
                <label>Sipariş Notunuz</label>
                <textarea
                  name="siparis_notu"
                  value={formData.siparis_notu}
                  onChange={handleInputChange}
                  placeholder="Sipariş Notunuz"
                />
              </div>

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  id="kullanim-kosullari"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
                <label htmlFor="kullanim-kosullari">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setCurrentTerm('kullanım-kosullari');
                    setModalIsOpen(true);
                  }}>Kullanım Koşulları</a> sözleşmesini okudum ve kabul ediyorum.
                </label>
              </div>

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  id="mesafeli-satis"
                  checked={salesTermsAccepted}
                  onChange={() => setSalesTermsAccepted(!salesTermsAccepted)}
                />
                <label htmlFor="mesafeli-satis">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setCurrentTerm('mesafeli-satis');
                    setModalIsOpen(true);
                  }}>Mesafeli Satış</a> sözleşmesini okudum ve kabul ediyorum.
                </label>
              </div>

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  id="teslimat-iade"
                  checked={deliveryTermsAccepted}
                  onChange={() => setDeliveryTermsAccepted(!deliveryTermsAccepted)}
                />
                <label htmlFor="teslimat-iade">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setCurrentTerm('teslimat-iade');
                    setModalIsOpen(true);
                  }}>Teslimat ve İade</a> sözleşmesini okudum ve kabul ediyorum.
                </label>
              </div>

              <button type="submit" className='button-apply'>Siparişi Tamamla</button>
            </form>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => {
                setModalIsOpen(false);
                setCurrentTerm('');
              }}
              contentLabel="Sözleşme Modal"
              className="Modal"
              overlayClassName="Overlay"
            >
              <button
                className="close-button"
                onClick={() => {
                  setModalIsOpen(false);
                  setCurrentTerm('');
                }}
              >
                ×
              </button>
              {getTermsContent()}
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;