import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductContext } from '../../context/ProductContext.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./ProductDetail.css";

import { CardContext } from '../../context/CardContext.js';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart } from '@fortawesome/free-regular-svg-icons';
import Popup from '../../components/PopUp.js';
import ImageGallery from './ImageGallery.js';
import {useImage} from "../../context/ImageContext.js";
import { Helmet } from "react-helmet-async";
import Loader from '../../components/Loader.js';


const ProductDetails = () => {
    const { sef } = useParams(); 
    const { getImageUrl } = useImage();
    const {addToCard}=useContext(CardContext);
    const { state, fetchProductById, fetchStoreProduct, fetchProducts } = useContext(ProductContext);
    const { products, loading, error, productDetails, store } =state;
    const { favorites, addToFavorite, removeFavorite} = useContext(FavoriteContext);
    const [activeTab, setActiveTab] = useState('description'); 
    const [message, setMessage] = useState('');
    const productLimit = 5;
    const [showPopup, setShowPopup] = useState(false);
    const [selectedSize, setSelectedSize] = useState(15);

    
    const shuffleArray = (array) => {
      let shuffledArray = [...array]; 
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
    };
    
    const handleAddToCart = () => {
      if ((productDetails.kategori.includes("75") || productDetails.kategori.includes("103") || productDetails.kategori.includes("112")) && !selectedSize) {
        setMessage("Lütfen ölçü seçin");
        setShowPopup(true);
        return; 
      }
  
      if (productDetails.kategori.includes("75") || productDetails.kategori.includes("103") || productDetails.kategori.includes("112")) {
        addToCard(productDetails.id, selectedSize);
    } else {
        
        addToCard(productDetails.id);
    }

      setMessage("Ürün Başarıyla Sepete Eklendi");
      setShowPopup(true);
    };
    const handleSizeChange = (event) => {
      setSelectedSize(event.target.value);
    };
  
    const handleContinueShopping = () => {
      setShowPopup(false);
     
    };
  
    const handleGoToCart = () => {
      
      window.location.href = "/card";
    };
    const closePopup = () => {
      setShowPopup(false);
    };
    useEffect(()=>{
      if (!products || products.id !== parseInt(sef)) {
        fetchProducts(sef);
    }
},[]);
  

    useEffect(() =>{ 
      if (!productDetails || productDetails.id !== parseInt(sef)) {
        fetchProductById(sef);
      }
    },[sef]);

    useEffect(() =>{
      if (!store || store.sef !== parseInt(sef)) {
       fetchStoreProduct(sef);
     }
    },[]);

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

      const isFavorite = (productDetails) => {
        return favorites && favorites.length > 0 && favorites.some(favProduct => favProduct.sef === productDetails.sef);
    };
    
    const handleFavoriteToggle = () => {
      if (isFavorite(productDetails)) {
          removeFavorite(productDetails);
         
      } else {
          addToFavorite(productDetails);
         
      }
  };
  const isOutOfStock = productDetails?.stok === 0; 
  
    if (!productDetails && loading) return <Loader />;
    if (error) return <div>Hata: {error}</div>;
    if (!loading && !productDetails) {
      return <div></div>;
    }
    
    return (
        <>
        <div>
         <Helmet>
        <title>BiKuyum |  {productDetails ? productDetails.baslik : 'Mağaza'}</title>
        </Helmet>
      
        </div>
        <div className='product-detail'>
         <div className="product-detail-container">
         
          <div className='imgs'>
          <ImageGallery 
        images={Object.values(productDetails.image).filter(image => image)} 
    />
         </div>
         <div className="product-info">
           <h1>{productDetails.baslik}</h1>
           <div className='product-actions'>
           
           <p>Satıcı Mağaza:</p><a href={`/${productDetails.magaza_sef}/all`}><p>{productDetails.magaza_adi} </p></a> <hr></hr>
           <div className="favorite" onClick={handleFavoriteToggle}>
                            <FontAwesomeIcon
                                icon={isFavorite(productDetails) ? SolidHeart : RegularHeart}
                                style={{ color: isFavorite(productDetails) ? 'red' : 'black', fontSize: window.innerWidth <= 768 ? '18px' : '24px'}}
                            />
                        </div>
           </div> 
           <div className="detail-prices">
        {productDetails.fiyat && productDetails.eski_fiyat !== productDetails.fiyat ? (
            <>
                <h2 className="detail-originall-price" style={{ textDecoration: 'line-through' }}>
                    {productDetails.eski_fiyat.toLocaleString('tr-TR')} TL
                </h2>
                <h2 className="detail-discountedd-price">
                    {productDetails.fiyat.toLocaleString('tr-TR')} TL
                </h2>
            </>
        ) : (
            <h2 className="detail-originall-price">
                {productDetails.fiyat.toLocaleString('tr-TR')} TL
            </h2>
        )}
    </div>
   
           <div className="payment-options">
            
              <span className='taksit-secenegi'>3 Taksit Seçeneği</span>
            </div>
           <p>Stok Kodu: {productDetails.stok_kodu}</p>
           {isOutOfStock && <p className='stok-bilgi'>Ürün stokta bulunmamaktadır.</p>} 
           <div className='explanation'><p>Ürünümüz <strong>{productDetails.ayar}</strong> ayar onaylanmış ve kontrol edilmiş <strong>{productDetails.gram}</strong> Gram .Altındır.
           Tüm sorularınız için, bizimle whatsapp"tan irtibata geçebilirsiniz.</p></div>
           {productDetails.variants && productDetails.variants.length > 0 && (
                        <div className="variants">
                          <div className='variant-title'><h4>Pırlanta Bilgileri</h4></div>
                            <ul>
                                {productDetails.variants.map((variant, index) => (
                                     
                                     <div key={index} className="variants-item">
                                      
                                      <div>
                                     <strong>{variant.variant_title}:</strong>
                                     <span> {variant.karat}</span></div>
                                 </div>
                                ))}
                            </ul>
                        </div>
                    )}
           {productDetails.kategori.includes("75") || productDetails.kategori.includes("103") || productDetails.kategori.includes("112") ? (
    <div className="ring-size">
        <p>Ölçü Seçin:</p>
    <select  onChange={handleSizeChange} value={selectedSize}>
      {Array.from({ length: 25 }, (_, index) => index + 6).map(size => (
        <option key={size} value={size}>
          {size}
        </option>
      ))}
    </select>
    {selectedSize !== 15 && (
                <p className="size-price-difference">Seçilen ölçü için 150 TL fiyat farkı eklenmiştir.</p>
              )}
            </div>
          ) : null}
         
           < div className="purchase-options">
            <a href><button onClick={handleAddToCart} className='card-button'>Sepete Ekle</button></a> 
            <a><button onClick={()=>{ addToCard(productDetails.id, selectedSize);
           
        setTimeout(() => {
          window.location.href = "/card";
        }, 300); 
      }} className='card-button-buy'>Hemen Satın Al</button></a>
             <a 
        href={`https://api.whatsapp.com/send?phone=905441000229&text=Merhaba, ${productDetails.sef} ürününü almak istiyorum.Link: ${encodeURIComponent(window.location.href)}`}
        target="_blank" 
        rel="noopener noreferrer">
        <button className='whatsapp'>Whatsapp ile Sipariş</button>
    </a>
             {showPopup && (
        <Popup
        message={message}
          onContinue={handleContinueShopping}
          onGoToCart={handleGoToCart}
          closePopup={closePopup} 
          duration={3000}
        />
      )}
           </div>
           
           <div className="seller-info">
            
           <div> <a href={`/${productDetails.magaza_sef}/all`}><img src={getImageUrl(productDetails.profil_fotografi)} alt="profil-fotografi" ></img></a></div>
           <div className='seller-info-store'>
              <p>Satıcı Mağaza</p>
              <a href={`/${productDetails.magaza_sef}/all` }> <p>{productDetails.magaza_adi}</p></a>
             
             <a href={`/${productDetails.magaza_sef}/all` }><button className="store">Mağazayı İncele</button></a>
            </div>
           </div>
         </div>
        
       </div>
       <div className='tabs-container'>
       <div className="tabs-header">
       <button  className={activeTab === 'description' ? 'active' : ''}
           onClick={() => setActiveTab('description')}>Açıklama</button>
        <button  className={activeTab === 'shipping' ? 'active' : ''}
          onClick={() => setActiveTab('shipping')}>Teslimat & İade</button>
         </div>
         <div className='tabs-content'>
         {activeTab === 'description' && (
          <div className='tab-pane'>
             <h4>{productDetails.baslik}</h4>
             <p>{productDetails.description}</p>
            <p>Tüm sorularınız için, bizimle <strong>WhatsApp</strong>'tan irtibata geçebilirsiniz.</p>
          </div> )}
          {activeTab ==="shipping" && (
            <div className='tab-pane'>
              <h4>Teslimat & İade Koşulları</h4>

<h6>GENEL:</h6>
<p>
  1. Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda sipariş verdiğiniz takdirde, 
  size sunulan ön bilgilendirme formunu ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız.
</p>
<p>
  2. Alıcılar, satın aldıkları ürünün satış ve teslimi ile ilgili olarak 6502 sayılı 
  Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) 
  hükümleri ile yürürlükteki diğer yasalara tabidir.
</p>
<p>
  3. Ürün sevkiyat masrafı olan kargo ücretleri alıcılar tarafından ödenecektir.
</p>
<p>
  4. Satın alınan her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile alıcının gösterdiği 
  adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürün teslim edilmez ise, 
  Alıcılar sözleşmeyi sona erdirebilir.
</p>
<p>
  5. Satın alınan ürün, eksiksiz ve siparişte belirtilen niteliklere uygun ve varsa garanti 
  belgesi, kullanım kılavuzu gibi belgelerle teslim edilmek zorundadır.
</p>
<p>
  6. Satın alınan ürünün satılmasının imkansızlaşması durumunda, satıcı bu durumu öğrendiğinden 
  itibaren 3 gün içinde yazılı olarak alıcıya bu durumu bildirmek zorundadır. 
  14 gün içinde de toplam bedel Alıcı’ya iade edilmek zorundadır.
</p>

<h6>SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:</h6>
<p>
  7. Alıcı, satın aldığı ürün bedelini ödemez veya banka kayıtlarında iptal ederse, 
  Satıcının ürünü teslim yükümlülüğü sona erer.
</p>

<h6>KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:</h6>
<p>
  8. Ürün teslim edildikten sonra, alıcının ödeme yaptığı kredi kartının yetkisiz kişiler 
  tarafından haksız olarak kullanıldığı tespit edilirse ve satılan ürün bedeli ilgili 
  banka veya finans kuruluşu tarafından Satıcı’ya ödenmez ise, Alıcı, sözleşme konusu ürünü 
  3 gün içerisinde nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya iade etmek zorundadır.
</p>

<h6>ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE:</h6>
<p>
  9. Satıcının öngöremeyeceği mücbir sebepler oluşursa ve ürün süresinde teslim edilemez 
  ise, durum Alıcı’ya bildirilir. Alıcı, siparişin iptalini, ürünün benzeri ile değiştirilmesini 
  veya engel ortadan kalkana dek teslimatın ertelenmesini talep edebilir. 
  Alıcı siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren 14 gün 
  içinde kendisine nakden bu ücret ödenir. Alıcı, ödemeyi kredi kartı ile yapmış ise 
  ve iptal ederse, bu iptalden itibaren yine 14 gün içinde ürün bedeli bankaya iade 
  edilir, ancak bankanın alıcının hesabına 2-3 hafta içerisinde aktarması olasıdır.
</p>

<h6>ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ:</h6>
<p>
  10. Alıcı, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek; ezik, 
  kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti kargo şirketinden 
  teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. 
  ALICI, teslimden sonra mal/hizmeti özenle korunmak zorundadır. Cayma hakkı kullanılacaksa 
  mal/hizmet kullanılmamalıdır. Ürünle birlikte fatura da iade edilmelidir.
</p>

<h6>CAYMA HAKKI:</h6>
<p>
  11. ALICI; satın aldığı ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa teslim 
  tarihinden itibaren 14 (on dört) gün içerisinde, SATICI’ya aşağıdaki iletişim bilgileri 
  üzerinden bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve 
  hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir.
</p>

<h6>SATICININ CAYMA HAKKI BİLDİRİMİ YAPILACAK İLETİŞİM BİLGİLERİ:</h6>
<p>
  ŞİRKET <br />
  Firma Ünvanı: SİMGE YILMAZ LORA BUTİK <br />
  Adres: MAHMUTBEY MAH. SOĞUKSU CAD. NO: 3 İÇ KAPI NO: 2 BAĞCILAR/İSTANBUL <br />
  E-posta: info@lorabutik.com.tr <br />
  Tel: 05455615672
</p>

<h6>CAYMA HAKKININ SÜRESİ:</h6>
<p>
  13. Alıcı, satın aldığı eğer bir hizmet ise, bu 2 günlük süre sözleşmenin imzalandığı 
  tarihten itibaren başlar. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile 
  hizmetin ifasına başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz.
</p>
<p>
  14. Cayma hakkının kullanımından kaynaklanan masraflar SATICI’ya aittir.
</p>
<p>
  15. Cayma hakkının kullanılması için 2 (iki) günlük süre içinde SATICI’ya iadeli 
  taahhütlü posta, faks veya e-posta ile yazılı bildirimde bulunulması ve ürünün 
  işbu sözleşmede düzenlenen “Cayma Hakkı Kullanılamayacak Ürünler” hükümleri 
  çerçevesinde kullanılmamış olması şarttır.
</p>

<h6>CAYMA HAKKININ KULLANIMI:</h6>
<p>
  16. 3. kişiye veya ALICI’ya teslim edilen ürünün faturası, (İade edilmek istenen 
  ürünün faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası 
  ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş 
  iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)
</p>
<p>
  17. İade formu, iade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları 
  ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.
</p>

<h6>İADE KOŞULLARI:</h6>
<p>
  18. SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 2 günlük süre 
  içerisinde toplam bedeli ve ALICI’yı borç altına sokan belgeleri ALICI’ya iade etmek 
  ve 2 günlük süre içerisinde malı iade almakla yükümlüdür.
</p>
<p>
  19. ALICI’nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa 
  veya iade imkansızlaşırsa ALICI kusuru oranında SATICI’nın zararlarını tazmin etmekle 
  yükümlüdür. Ancak cayma hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması 
  sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu değildir.
</p>
<p>
  20. Cayma hakkının kullanılması nedeniyle SATICI tarafından.</p>
            </div>
          )}
          </div>
          </div>
         <div className='recommendation-container'>
          <h3>Tavsiye Ettiğimiz Ürünler</h3>
          <div className='recommendation'>
            {shuffleArray(products).slice(0, productLimit).map(productt =>(
                <div key={productt.sef} className="recommendation-card">
                    <div className="recommendation-img-container">
                    <a href={`/urun/${productt.sef}`} >
                    <img src={getImageUrl(productt.image.i1)} alt={productt.baslik} /></a>
                     <div 
                    
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleFavoriteToggle(e, productt)} }
                                                            className="favorite-iconn" 
                                                        >
                                                            <FontAwesomeIcon
                                                    icon={isFavorite(productt) ? SolidHeart : RegularHeart}
                                                    style={{ color: isFavorite(productt) ? 'red' : 'gray', fontSize: '24px' }}
                                                />
                                                        </div>
                    </div>
                    
                    <div className="recommendation-card-responsive__info">
            <p className="recommendation-card-responsive-name">
                <a href={`/urun/${productt.sef}`}>{productt.baslik}</a>
              </p>
            <div className="ratingg">
              {'★'.repeat(5)}
            </div>
            <div className="pricee">
        {productt.fiyat && productt.eski_fiyat !== productt.fiyat ? (
            <>
                <h2 className="originall-price" style={{ textDecoration: 'line-through' }}>
                    {productt.eski_fiyat.toLocaleString('tr-TR')} TL
                </h2>
                <h2 className="discountedd-price">
                    {productt.fiyat.toLocaleString('tr-TR')} TL
                </h2>
            </>
        ) : (
            <h2 className="originall-price">
                {productt.fiyat.toLocaleString('tr-TR')} TL
            </h2>
        )}
    </div>
           
             </div>
                </div>
            ))}
            
          </div>
         </div>
         </div>
         
       </>
    );
};

export default ProductDetails;