import React, { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../../context/ProductContext.js';
import { Container } from '@mui/material';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import "./ProductList.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart } from '@fortawesome/free-regular-svg-icons';
import {useImage} from "../../context/ImageContext.js";
import Loader from '../../components/Loader.js';

const ProductList = () => {
    const { getImageUrl } = useImage();
    const { favorites, addToFavorite, removeFavorite} = useContext(FavoriteContext);
    const { state, fetchProducts, dispatch } = useContext(ProductContext);
    const { products, loading, error } = state;
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
  
    
    const isFavorite = (product) => {
        return favorites.some(favProduct => favProduct.id === product.id);
    };

    const handleFavoriteToggle = (event, product) => {

          event.stopPropagation();  
        event.preventDefault();
        if (isFavorite(product)) {
            removeFavorite(product);
        } else {
            addToFavorite(product);
        }
    };
   
   
    useEffect(() => {
        const loadProducts = async () => {
            if (isFetching) return;  
            setIsFetching(true);  
            await fetchProducts(currentPage);
            setIsFetching(false); 
        };
        loadProducts();
    }, [currentPage ]);

    useEffect(() => {
        dispatch({ type: 'RESET_PRODUCTS' });
        
    }, []);

    
    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = window.innerWidth < 768 ? 1300 : 700;
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight  - scrollThreshold &&
                !isFetching &&
                !loading
            ) {
                setCurrentPage(prev => prev + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [ loading]);



    if (loading) return <Loader />;
    if (error) return <div>Hata: {error}</div>;

    
    return (
        <>
        
        <div className="products-container">
            <div className="productt-grid">
            
            {products.map((product, index) => (
                 <a
                 key={`${product.id}-${index}`}
                 href={`/urun/${product.sef}`}
                 target="_blank"
                 rel="noopener noreferrer"
                 className="productt-card"
                 style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }} 
               >
               
                    <div className="productt-img-container" >
                  
                    <img src={getImageUrl(product.image.i1)} alt={product.baslik}  onError={(e) => {
                        e.target.onerror = null;  }}/>
                          <div 

                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleFavoriteToggle(e, product)} }
                                        className="favorite-iconn" 
                                    >
                                        <FontAwesomeIcon
                                icon={isFavorite(product) ? SolidHeart : RegularHeart}
                                style={{ color: isFavorite(product) ? 'red' : 'gray', fontSize: window.innerWidth <= 768 ? '18px' : '24px' }}
                            />
                                    </div>
                        </div>
                    <div className="productt-card-responsive__info">
            <h3 className="productt-card-responsive__name">
                {product.baslik}
              </h3>
            <div className="ratingg">
              {'★'.repeat(5)}
            </div>
            <div className="pricee">
        {product.fiyat && product.eski_fiyat !== product.fiyat ? (
            <>
                <h2 className="originall-price" style={{ textDecoration: 'line-through' }}>
                    {product.eski_fiyat.toLocaleString('tr-TR')} TL
                </h2>
                <h2 className="discountedd-price">
                    {product.fiyat.toLocaleString('tr-TR')} TL
                </h2>
            </>
        ) : (
            <h2 className="originall-price">
                {product.fiyat.toLocaleString('tr-TR')} TL
            </h2>
        )}
    </div>
           
           
             {product.stok === 0 && (

                    <p className="stock-warning" style={{ color: 'red', fontWeight: 'bold' }}>
                        Stok bulunamadı
                    </p>
                )}
           
           
            {!product.image.i1 && <p>Ürün fotoğrafı bulunamadı.</p>}
             </div>
                
                 </a>
            ))}
            
            </div>
               
      {isFetching && (
        <div className='loader-container'>  
        <div className="loader"></div></div>
      )}
            </div>
            
            </>
    );
};

export default ProductList;