import React, { createContext, useReducer, useEffect } from 'react';


const initialState={
    certificates:null,
    error: null,  
    loading: false, 
}

const certificateReducer = (state, action) => {
switch(action.type){
    case 'FETCH_CERTIFICATE_SUCCESS':
        return{...state, certificates:action.payload, loading: false};
     case 'FETCH_CERTIFICATE_ERROR':
            return { ...state, error: action.payload, loading: false };
        case 'FETCH_CERTIFICATE_LOADING':
            return { ...state, loading: true };
default: 
return state;
};
};
export const CertificateContext=createContext();

export const CertificateProvider =({children})=>{
    const [state, dispatch] = useReducer(certificateReducer, initialState);


    const certificate = async (cardNo) => {
   
        try {
           
            const response = await fetch(`https://api.bikuyum.com/api/certificate`,{
                method: 'POST',
                headers: {
                  
                  'Content-Type': 'application/json',
                  
                },
                body: JSON.stringify({
                  cardNo:cardNo
                 })
              });
              const data = await response.json();
              
           
             dispatch({ type: 'FETCH_CERTIFICATE_SUCCESS', payload: data }); 
    
        } catch (error) {
            dispatch({ type: 'FETCH_CERTIFICATE_ERROR', payload: error.message });
        }
    };
   
    


return (
        <CertificateContext.Provider value={{ ...state, certificate }}>
            {children}
        </CertificateContext.Provider>
    );    
}
