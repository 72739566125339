import React, { createContext, useReducer, useEffect } from 'react';
import axios from 'axios';

const initialState={
    favorites:[],
}

const favoriteReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TO_FAVORITES':
           
        return { ...state, favorites:   [...state.favorites, action.payload]  };

        case 'REMOVE_FROM_FAVORITES':
            
            return { ...state, favorites: state.favorites.filter(product => product.id !== action.payload.id) };
        case 'LOAD_FAVORITES':
         
            return { ...state, favorites: action.payload};
        case 'UPDATE_FAVORITE':
            return {
                ...state,
                favorites: state.favorites.map(item => 
                    item.sef === action.payload.sef ? { ...item, fiyat: action.payload.fiyat } : item
                ),
            };
    
        default:
            return state;
    }
};
export const FavoriteContext=createContext();

export const FavoriteProvider = ({ children }) => {
    const [state, dispatch] = useReducer(favoriteReducer, initialState);

    const addToFavorite = async (product) => {
        try{
        const token = localStorage.getItem('token');
        const tkn= localStorage.getItem('tkn');
        const response = await fetch(`https://api.bikuyum.com/api/favorites`,{
          method: 'POST',
          headers: {
            'Authorization': `${token}`,
            'Tkn':`${tkn}`, 
            'Content-Type': 'application/json',
            
          },
          body: JSON.stringify({
            urun_id: product.id   })
        });
        const data = await response.json();
    
        if (data.message) {
            dispatch({ type: 'ADD_TO_FAVORITES', payload: product });
            const updatedFavorites = [...state.favorites, product];
            localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
          }
      } catch (error) {
        console.error("Error adding product to cart:", error);
      }
     fbq('track', 'AddToWishlist');
       
    };
    
    const removeFavorite = async (product) => {
        try {
          const token = localStorage.getItem('token');
          const tkn = localStorage.getItem('tkn');
          const response = await fetch(`https://api.bikuyum.com/api/favorites?id=${product.id}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `${token}`,
              'Tkn':`${tkn}`, 
              'Content-Type': 'application/json',
        
            },
            
          });
          const data = await response.json();
    
    
    if (data.message) {
      dispatch({ type: 'REMOVE_FROM_FAVORITES', payload: product }); 
      const updatedFavorites = state.favorites.filter(fav => fav.id !== product.id);
      localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    }
        } catch (error) {
          console.error('Error removing product from favorites:', error);
        }
      };

    const loadFavorites = async () => {
        try {
          const token = localStorage.getItem('token');
          const tkn = localStorage.getItem('tkn');
          const response = await fetch('https://api.bikuyum.com/api/favorites', {
            method: 'GET',
            headers: {
              'Authorization': `${token}`,
              'Tkn':`${tkn}`, 
              'Content-Type': 'application/json',
              
            },
          
          });
          const data = await response.json();
        
          const correctedData = data.map(favorites => {
            if (typeof favorites.image === 'string') {
                try {
                    const imageUrls = JSON.parse(favorites.image.replace(/\\/g, ''));
                    return { ...favorites, image: imageUrls };
                } catch (error) {
                    console.error('Image JSON parse edilemedi:', error);
                    return favorites;
                }
            }
            return favorites;
        });
          
        localStorage.setItem('favorites', JSON.stringify(correctedData));
        dispatch({ type: 'LOAD_FAVORITES', payload: correctedData });
       
                } catch (error) {
               dispatch({ type: 'FETCH_CARD_ERROR', payload: error.message });
                }
      };
   
      useEffect(() => {
        const savedFavorites = localStorage.getItem('favorites');
        if (savedFavorites) {
            dispatch({ type: 'LOAD_FAVORITES', payload: JSON.parse(savedFavorites) });
        } else {
            loadFavorites();
        }
    }, []);
    return (
        <FavoriteContext.Provider value={{favorites:state.favorites, addToFavorite, removeFavorite }}>
            {children}
        </FavoriteContext.Provider>
    );
};

