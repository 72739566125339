import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

const Link = () => {
  const a = window.location.href;
  const {r}=useParams();

 
 
  useEffect(() => {
    const sendVisitData = async (a) => {
     
      const afterR = a.split('?')[1]; 
  
      if(!afterR){
        return;
      }
      try {
        await axios.get(`https://api.bikuyum.com/api/kampanya?${afterR}`)
        console.log("GET isteği gönderildi:", afterR);
      } catch (error) {
        console.error("GET isteği başarısız:", error);
      }
    };
  
    sendVisitData(a);
  }, [a,r]); 

  return null; 
};


export default Link;